import initChemUtils from '@cdd/ui-kit/lib/chemUtils';

const Window = globalThis as typeof window;

// returns the current global ChemUtils instance - or null if it has not been loaded yet
export function peekChemUtils(): ChemUtils {
  return Window.chemUtils;
}

// if the global ChemUtils instance is already loaded, returns it immediately; if not, fires up the loading mechanism, which can take
// up to a few seconds; a return value of null indicates failure
let initChemUtilsPromise: Promise<typeof window.chemUtils>;

export async function ensureChemUtils(): Promise<ChemUtils> {
  if (initChemUtilsPromise) {
    return initChemUtilsPromise;
  }
  initChemUtilsPromise = initChemUtils();
  Window.chemUtils = await initChemUtilsPromise;
  return Window.chemUtils;
}

// no reason to call this except for unit testing - clears state.
export function clearChemUtils() {
  initChemUtilsPromise = null;
  Window.chemUtils = null;
}

// no reason to call this except for unit testing - stubs the ChemUtils instance.
export function stubChemUtils(stub: () => Promise<Partial<ChemUtils>>): Promise<ChemUtils> {
  const wrapChemUtils = async () => {
    return Object.assign({}, await initChemUtils(), await stub());
  };
  return initChemUtilsPromise = wrapChemUtils();
}
